import { generatePdf } from "./bicGenerate/generatePdf";

const url = process.env.REACT_APP_API;
// const url = "https://grizzly-healthy-monitor.ngrok-free.app";

export const Api = {
  async loginPost(infoLogin) {
    try {
      const response = await fetch(url + "/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(infoLogin),
      });

      const data = await response.json();

      sessionStorage.setItem("infoUsuarioEmail", data.data.email);
      sessionStorage.setItem("infoUsuarioToken", data.data.token);
      return data;
    } catch (error) {
      throw error;
    }
  },

  async numeroInscricaoPost(objeto) {
    try {
      const response = await fetch(url + "/imoveis/info", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async updateProprietarioImovel(objeto) {
    try {
      const response = await fetch(url + "/imoveis/proprietario/save", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async imoveisSavePost(objeto) {
    try {
      const response = await fetch(url + "/imoveis/save", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async showPontoInteresse(objeto) {
    try {
      const response = await fetch(url + "/imoveis/pontoInteresse/show", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async createPontoInteresse(objeto) {
    try {
      const response = await fetch(url + "/imoveis/pontoInteresse/create", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async updatePontoInteresse(objeto) {
    try {
      const response = await fetch(url + "/imoveis/pontoInteresse", {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async updateCobertura(objeto) {
    try {
      const response = await fetch(url + "/imoveis/cobertura/save", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async getPontoInteresse(objeto) {
    try {
      const response = await fetch(
        url + "/imoveis/pontoInteresse?page=1&limit=100" /*+ objeto*/,
        {
          method: "GET",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
            "Content-Type": "application/json",
            "access-control-allow-origin": "*",
          },
          body: JSON.stringify(),
        }
      );
      const data = await response.json();

      localStorage.setItem("infoTotalPontoInteresse", data.totalItems);
      return data;
    } catch (error) {
      return error;
    }
  },

  async deletePontoInteresse(id) {
    try {
      const response = await fetch(url + "/imoveis/pontoInteresse/" + id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      throw error;
    }
  },

  async proprietariosCadastrados(totalProprietarios) {
    try {
      const response = await fetch(
        url + "/proprietarios?page=0&limit=0" + totalProprietarios,
        {
          method: "GET",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
            "ngrok-skip-browser-warning": "any",
            "access-control-allow-origin": "*",
          },
          body: JSON.stringify(),
        }
      );

      const data = await response.json();
      localStorage.setItem(
        "infoTotalProprietarios",
        data.data.result.totalItems
      );
      return data;
    } catch (error) {
      throw error;
    }
  },

  async addProprietarios(infoCadastro) {
    try {
      const response = await fetch(url + "/proprietarios/create", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(infoCadastro),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteProprietario(id) {
    try {
      const response = await fetch(url + "/proprietarios/" + id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async searchProprietario(proprietarioDados) {
    try {
      const response = await fetch(url + "/proprietarios/search", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(proprietarioDados),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async searchCpfProprietario(cpf) {
    try {
      const response = await fetch(url + "/proprietarios/search/cpf", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(cpf),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async updateProprietario(obj) {
    try {
      const response = await fetch(url + "/proprietarios/save", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(obj),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async searchEixo(nomeRua) {
    try {
      const response = await fetch(url + "/eixos/search", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify({
          logradouro: nomeRua,
        }),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async eixosCadastrados(idEixos) {
    try {
      const response = await fetch(url + "/eixos/" + idEixos, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },

  async addEixo(infoEixo) {
    console.log(infoEixo);
    try {
      const response = await fetch(url + "/eixos/save", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(infoEixo),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async searchLogradouro(nomeRua) {
    try {
      const response = await fetch(url + "/logradouros/search", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify({
          logradouro: nomeRua,
        }),
      });

      const data = await response.json();

      return data;
    } catch (error) {
      return error;
    }
  },

  async logradourosCadastrados(idLogradouro) {
    try {
      const response = await fetch(url + "/logradouros/" + idLogradouro, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },

  async addLogradouro(infoLogradouro) {
    try {
      const response = await fetch(url + "/logradouros/save", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(infoLogradouro),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async searchInscricaoReduzida(objeto) {
    try {
      const response = await fetch(url + "/map/cod", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async searchNumInscricao(objeto) {
    try {
      const response = await fetch(url + "/map/inscr", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async searchEndereco(objeto) {
    try {
      const response = await fetch(url + "/map/endereco", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
  async getUsers(objeto) {
    try {
      const response = await fetch(
        url + "/users/search?page=0&limit=0" + objeto,
        {
          method: "POST",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
            "Content-Type": "application/json",
            "access-control-allow-origin": "*",
          },
          body: JSON.stringify(),
        }
      );
      const data = await response.json();
      localStorage.setItem("infoTotalUsuarios", data.data.result.limit);
      return data;
    } catch (error) {
      return error;
    }
  },
  async getUsersId(id) {
    try {
      const response = await fetch(url + "/users/" + id, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
  async newUsers(objeto) {
    try {
      const response = await fetch(url + "/users", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },
  async editUsers(objeto) {
    try {
      const response = await fetch(url + "/users", {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async deleteUsers(id) {
    try {
      const response = await fetch(url + "/users/" + id, {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(),
      });

      return response;
    } catch (error) {
      throw error;
    }
  },

  async getAlterarPerfil() {
    try {
      const response = await fetch(url + "/users", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async alterarPerfilRequest(objeto) {
    try {
      const response = await fetch(url + "/users", {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getAdversidades() {
    try {
      const response = await fetch(url + "/adversidades", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(),
      });

      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },

  async getAdversidadesId(id) {
    try {
      const response = await fetch(url + "/adversidades/" + id, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  },

  async getCoord(objeto) {
    try {
      const response = await fetch(url + "/map/coord", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(objeto),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getColeta() {
    try {
      const response = await fetch(url + "/coleta/list", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getMapaColeta() {
    try {
      const response = await fetch(url + "/map/setores", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getMapa() {
    try {
      const response = await fetch(url + "/map", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",

          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getMapaCodigo(cod) {
    try {
      const response = await fetch(url + "/map/cod", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify({
          cod: cod,
        }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getSearchXY(cod) {
    try {
      const response = await fetch(url + "/imoveis/searchXY/" + cod, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getLotes() {
    try {
      const response = await fetch(url + "/app/mapQuadras", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getAllQuadras() {
    try {
      const response = await fetch(url + "/map/allQuadras", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getQuadrasCod(setorCod) {
    try {
      const response = await fetch(url + "/map/setor/quadras/" + setorCod, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getEixos() {
    try {
      const response = await fetch(url + "/app/Eixos", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getEixosForQuadra(idQuadra) {
    try {
      const response = await fetch(url + "/app/eixos?quadras=" + idQuadra, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getLotesForQuadra(idQuadra) {
    try {
      const response = await fetch(url + "/app/lotes?quadras=" + idQuadra, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getMarchaUrbana() {
    try {
      const response = await fetch(url + "/map/manchaUrbana", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getLoteForId(idLote) {
    try {
      const response = await fetch(url + "/app/lote/" + idLote, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getColetores() {
    try {
      const response = await fetch(url + "/app/users", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getAlocarColetoresQuadra(dadosAlocacao) {
    try {
      const response = await fetch(url + "/app/quadrasColetor", {
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(dadosAlocacao),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getQuadraInfo(dadosQuadra) {
    try {
      const response = await fetch(url + "/coleta/quadrasInfo", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(dadosQuadra),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getSaveColeta(dadosQuadra) {
    try {
      const response = await fetch(url + "/coleta/save", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(dadosQuadra),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getSaveColetaImagens(img) {
    console.log(img);
    try {
      const response = await fetch(url + "/coleta/saveFile", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
        body: JSON.stringify(img),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getColetoresId(idColetor) {
    try {
      const response = await fetch(url + "/app/users/" + idColetor, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getImagensStreetView(idLote) {
    try {
      const response = await fetch(url + "/coleta/images-for-lote/" + idLote, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return error;
    }
  },

  async getRelatoriosPois() {
    try {
      const response = await fetch(url + "/relatorios/pontointeresse", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("infoUsuarioToken"),
          "Content-Type": "application/json",
          Accept: "text/csv",
          "access-control-allow-origin": "*",
          "ngrok-skip-browser-warning": "any",
        },
      });
      const blob = await response.blob();
      const url2 = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url2;
      link.setAttribute("download", "relatorio_Ponto_Interesse.csv");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      return error;
    }
  },

  async postTestUpload(blobUrls, loteid, angulo) {
    // Cria o FormData
    const formData = new FormData();
    formData.append("angulo", angulo);
    formData.append("loteid", loteid);

    try {
      // Adiciona cada imagem do array ao FormData
      for (let i = 0; i < blobUrls.length; i++) {
        const blobResponse = await fetch(blobUrls[i]);
        const blob = await blobResponse.blob();

        // Nomeia o arquivo de forma única
        formData.append("arquivos", blob, `upload_${i + 1}.jpg`);
      }

      // Envia a requisição
      const response = await fetch(`${url}/coleta/test-upload`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("infoUsuarioToken")}`,
          "ngrok-skip-browser-warning": "any",
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Response data:", data);
      return data;
    } catch (error) {
      console.error("Error in postTestUpload:", error);
      return { error: error.message };
    }
  },

  // Função para a busca as informações de um imóvel e consequentemente gerar o pdf (BIC)
  async getBicData(id) {
    try {
      const response = await fetch(`${url}/bic/info/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.status}`);
      }

      const data = await response.json();

      if (data?.imovel && !(data?.imovel?.length > 0)) {
        return undefined;
      }

      // generatePdf(data, options);

      return data;
    } catch (error) {
      return undefined;
    }
  },
};
