import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  TextField,
  Fab,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import "./ImprimirBic.css";
import { getBicByCod } from "../../services/getBicByCodService";
// import { getBicPdf } from "../../services/getBicPdf";

import { ModalImprimir } from "./ModalImprimir";
import { Api } from "../../services/Api";
import GeneratePdfComponent from "./mapBic/GeneratePdfComponent";

function ImprimirBic() {
  const [numInscricao, setNumInscricao] = useState("");
  const [numInscricaoReduzida, setNumInscricaoReduzida] = useState("");
  const [searching, setSearching] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [resultReturned, setResultReturned] = useState(false);
  const [respostaApi, setRespostaApi] = useState("");

  async function submit() {
    setRespostaApi("");
    setResultReturned(false);
    if (
      (numInscricao !== "" && numInscricao.length === 22) ||
      numInscricaoReduzida !== ""
    ) {
      setSearching(true);
      const codigoImovel = numInscricaoReduzida || numInscricao;
      try {
        const response = await getBicByCod({ cod: codigoImovel });
        if (!response.error) {
          setRespostaApi(response);
          setResultReturned(true);
        } else {
          alert("O número solicitado não existe");
        }
      } catch (error) {
        alert(error.message);
      } finally {
        setSearching(false);
      }
    }
  }

  // Enter Envia
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  const toggleModal = () => setOpenPrint(!openPrint);

  return (
    <>
      {!openPrint ? (
        <div className='ImprimirBic-main-container'>
          <div className='ImprimirBic-container-imovel' style={{ padding: 10 }}>
            <div className='ImprimirBic-conteudo-imovel'>
              <TextField
                className='ImprimirBic-input-imovel'
                label='N° de inscrição reduzido'
                variant='outlined'
                size='small'
                value={numInscricaoReduzida}
                onChange={(e) => setNumInscricaoReduzida(e.target.value)}
                sx={{ margin: "5px", backgroundColor: "white" }}
                onKeyPress={(event) => handleKeyPress(event)}
                autoComplete='off'
              />
            </div>

            {resultReturned ? (
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label='caption table'>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          borderBottom: "2.5px solid #1976D2",
                          width: "150px",
                        }}
                      >
                        Nº de inscrição
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          borderBottom: "2.5px solid #1976D2",
                          width: "100px",
                        }}
                      >
                        Logradouro
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          borderBottom: "2.5px solid #1976D2",
                          width: "100px",
                        }}
                      >
                        Bairro
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          borderBottom: "2.5px solid #1976D2",
                          width: "100px",
                        }}
                      >
                        Reduzido
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          borderBottom: "2.5px solid #1976D2",
                          width: "100px",
                        }}
                      >
                        Número
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          borderBottom: "2.5px solid #1976D2",
                          width: "100px",
                        }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ minWidth: "30px", maxWidth: "100px" }}>
                        {respostaApi.data.inscricaoImovel}
                      </TableCell>
                      <TableCell sx={{ minWidth: "30px", maxWidth: "100px" }}>
                        {respostaApi.data.logradouro.nome}
                      </TableCell>
                      <TableCell sx={{ minWidth: "30px", maxWidth: "100px" }}>
                        {respostaApi.data.endereco.bairro}
                      </TableCell>
                      <TableCell sx={{ minWidth: "30px", maxWidth: "100px" }}>
                        {respostaApi.data.unidade.id}
                      </TableCell>
                      <TableCell sx={{ minWidth: "30px", maxWidth: "100px" }}>
                        {respostaApi.data.endereco.numero}
                      </TableCell>
                      <TableCell sx={{ minWidth: "30px", maxWidth: "100px" }}>
                        <Button
                          sx={{ height: "35px", border: "1px solid #1976D2" }}
                          onClick={toggleModal}
                        >
                          <FileDownloadIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <div
                className='ImprimirBic-conteudo-lista'
                style={{ height: "50px", padding: 0 }}
              ></div>
            )}
          </div>

          <div className='ImprimirBic-barra-direita'>
            <Fab
              sx={{ margin: "5px", marginBottom: "-10px", fontSize: "20px" }}
              color='primary'
              onClick={submit}
            >
              {searching ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              )}
            </Fab>
          </div>
        </div>
      ) : (
        <GeneratePdfComponent
          id={numInscricaoReduzida || numInscricao}
          closePrint={toggleModal}
        />
      )}
    </>
  );
}

export default ImprimirBic;
