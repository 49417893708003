import React, { useEffect, useRef, useState } from 'react';
import './styles.css';
import { FirstMap } from './maps/FirstMap';
import { SecondMap } from './maps/SecondMap';
import { ThirdMap } from './maps/ThirdMap';
import { LineString } from 'ol/geom';
import { Button, Checkbox } from '@mui/material';
import { Api } from '../../../services/Api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPrint, faSpinner } from '@fortawesome/free-solid-svg-icons';

const GeneratePdfComponent = ({ id, closePrint }) => {
  const [data, setData] = useState(null)
  const [searching, setSearching] = useState(false)

  async function generatePdf() {
    try {
      setSearching(true)
      const { data } = await Api.getBicData(id);
      setData(data)
    } catch (error) {
      setSearching(false)
      alert(error.message);
    }
    setSearching(false)
  }


  const map1 = useRef();
  const map2 = useRef();
  const map3 = useRef();

  const [valuesLat, setValuesLat] = useState([0, 0, 0, 0]);
  const [valuesLon, setValuesLon] = useState([0, 0, 0, 0, 0, 0]);
  const [planta, setPlanta] = useState(false);
  const [fotografia, setFotografia] = useState(false);

  const currentdate = new Date();
  const today =
    currentdate.getDate() +
    "/" +
    (currentdate.getMonth() + 1) +
    "/" +
    currentdate.getFullYear() +
    " - " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  const sanitizeValue = (value, fallback = "Sem registro") => {
    if (
      value === "" ||
      value === " " ||
      value == null ||
      (typeof value === "number" && isNaN(value)) ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim() === "")
    ) {
      return fallback;
    }
    return value;
  };

  const handleWindowPrint = () => {
    closePrint()
  }

  const handlePrint = () => {
    window.print(); // Wait for DOM updates, then invoke print
  };

  const handleCheckbox = (type) => {
    if (type === 'Fotografia')
      setFotografia(!fotografia)
    if (type === "Planta") {
      setPlanta(!planta)
      map1.current = null
      map2.current = null
      map3.current = null
    }
  };

  const handleCalculateExtent = (map) => {
    const extend = map.getView().calculateExtent();

    const bottomLine = new LineString([
      [extend[0], extend[1]],
      [extend[2], extend[1]],
    ]);

    const leftLine = new LineString([
      [extend[0], extend[1]],
      [extend[0], extend[3]],
    ]);

    const leftLinePoints = [
      leftLine.getCoordinateAt(0)[1].toFixed(2),
      leftLine.getCoordinateAt(0.3333333333333333)[1].toFixed(2),
      leftLine.getCoordinateAt(0.6666666666666666)[1].toFixed(2),
      leftLine.getCoordinateAt(1)[1].toFixed(2),
    ];

    const bottomLinePoints = [
      bottomLine.getCoordinateAt(0)[0].toFixed(2),
      bottomLine.getCoordinateAt(0.2)[0].toFixed(2),
      bottomLine.getCoordinateAt(0.4)[0].toFixed(2),
      bottomLine.getCoordinateAt(0.6)[0].toFixed(2),
      bottomLine.getCoordinateAt(0.8)[0].toFixed(2),
      bottomLine.getCoordinateAt(1)[0].toFixed(2),
    ];

    setValuesLat(leftLinePoints);
    setValuesLon(bottomLinePoints);
  };
  useEffect(() => {
    generatePdf()
  }, [])

  if (!data) {
    return (
      searching ? (
        <div className='tpage-simulation'>
          <div className='tcontent-actions' style={{ display: 'flex', justifyContent: "center", alignItems: "center", fontSize: "24px" }}>
            <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: "24px" }} />
            <p style={{ fontSize: "24px" }}>Carregando...</p>
          </div>
        </div>
      ) : (
        <div className='tpage-simulation'>
          <div className='tcontent-actions'>
            <button
              className="taction-button"
              onClick={handleWindowPrint}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
              Voltar
            </button>
          </div>
          <div className='tcontent-actions'>
            <div className='tpage' style={{ display: 'flex', alignItems: 'center' }}>
              <h1>Dados não encontrados</h1>
            </div>
          </div>
        </div>
      )
    );
  }

  return (
    <div className='tpage-simulation'>
      <div className='tcontent-actions'>
        <button
          className="taction-button"
          onClick={handleWindowPrint}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          Voltar
        </button>
        <section>
          <div className='tcheckbox-button'>
            <Checkbox onChange={() => handleCheckbox("Fotografia")} />
            <p>Fotografia</p>
          </div>
          <div className='tcheckbox-button'>
            <Checkbox onChange={() => handleCheckbox("Planta")} />
            <p>Planta</p>
          </div>
        </section>
        <button
          className="taction-button"
          variant='text' onClick={handlePrint}>
          <FontAwesomeIcon icon={faPrint} />
          Imprimir
        </button>
      </div>
      <div className="tpage">
        <div className="tpage-header">
          <div className="tdescription">
            <p className="ttext-header">PREFEITURA MUNICIPAL DE ITAJUBÁ</p>
            <p className="ttext-header">Emissão: {today}</p>
          </div>

          <div className="tcontent">
            <img src="./prefecture-icons/icon_itajuba.png" alt="logo" width="62" height="62" />
            <div className="ttitle">
              <h1 className="ttext-header-bold">PREFEITURA MUNICIPAL DE ITAJUBÁ</h1>
              <h2 className="ttext-header">ESTADO DE MINAS GERAIS</h2>
            </div>
          </div>
        </div>

        <div className='tbox-section'>
          <section>
            <div className='tsection-header'>
              <span>Dados Gerais</span>
              <span style={{ fontSize: '0.9rem' }}>Boletim de Inscrição Cadastral - BIC</span>
            </div>

            <div className='tcontainer'>
              <div className='tcontainer-p'>
                <p className="ttext">INSCRIÇÃO/REDUZIDO:</p>
                <p className="ttext">PROPRIETÁRIO: </p>
                <p className="ttext">CPF/CNPJ: </p>
                <p className="ttext">ENDEREÇO: </p>
              </div>
              <div className='tcontainer-span'>
                <p className="ttext-span">{sanitizeValue(data?.inscricaoImovel)} / {sanitizeValue(data?.unidade?.id)}</p>
                <p className="ttext-span">{sanitizeValue(data?.proprietario?.nome)}</p>
                <p className="ttext-span">{sanitizeValue(data?.proprietario?.cpfCnpj)}</p>
                <p className="ttext-span">{sanitizeValue(data?.proprietario?.endereco)}</p>
              </div>
            </div>
          </section>



          <section>
            <div className='tsection-header'>
              <span>Localização do imóvel</span>
            </div>

            <div className='tcontainer-duplo'>
              <div className='tcontainer'>
                <div className='tcontainer-p'>
                  <p className="ttext">LOGRADOURO:</p>
                  <p className="ttext">BAIRRO: </p>
                  <p className="ttext">COMPLEMENTO: </p>
                  <p className="ttext">MUNICÍPIO/UF: </p>
                </div>
                <div className='tcontainer-span'>
                  <p className="ttext-span">{sanitizeValue(data?.logradouro?.nome)}</p>
                  <p className="ttext-span">{sanitizeValue(data?.endereco?.bairro)}</p>
                  <p className="ttext-span">{sanitizeValue(data?.endereco?.complemento)}</p>
                  <p className="ttext-span">ITAJUBÁ/MG</p>
                </div>
              </div>

              <div className='tcontainer'>
                <div className='tcontainer-p'>
                  <p className="ttext">NÚMERO:</p>
                  <p className="ttext">SETOR/QUADRA: </p>
                  <p className="ttext">LOTE/UNID: </p>
                </div>
                <div className='tcontainer-span'>
                  <p className="ttext-span">{sanitizeValue(data?.endereco?.numero)}</p>
                  <p className="ttext-span">{sanitizeValue(data?.lote?.setorCod)} / {sanitizeValue(data?.lote?.quadraCod)}</p>
                  <p className="ttext-span">{sanitizeValue(data?.lote?.loteCod)} / {sanitizeValue(data?.lote?.unidade)}</p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className='tsection-header'>
              <span>Informações do lote</span>
            </div>

            <div className='tcontainer'>
              <div className='tcontainer-p'>
                <p className="ttext">ÁREA DO LOTE:</p>
                <p className="ttext">FRAÇÃO IDEAL: </p>
                <p className="ttext">TESTADA PRINCIPAL: </p>
              </div>
              <div className='tcontainer-span-table'>
                <p className="ttext-span">{sanitizeValue(data?.lote?.areaTerreno)} m²</p>
                <p className="ttext-span">{sanitizeValue(data?.lote?.fracaoIdealInfo)} m²</p>
                <p className="ttext-span">{sanitizeValue(data?.testadaPrincipal?.extensao)} m²</p>
              </div>

              <div className='tcontainer-table'>
                <header className='tcontainer-table-header'> <p className='tcontainer-p-table'>CARACTERÍSTICAS DO LOTE</p></header>
                <div className='tcontainer'>
                  <div className='tcontainer'>
                    <div className='tcontainer-p'>
                      <p className="ttext-table">OCUPAÇÃO:</p>
                      <p className="ttext-table">PATRIMÔNIO:</p>
                      <p className="ttext-table">SITUAÇÃO:</p>
                      <p className="ttext-table">PEDOLOGIA:</p>
                    </div>
                    <div className='tcontainer-span-table'>
                      <p className="ttext-span">{sanitizeValue(data?.lote?.ocupacao)}</p>
                      <p className="ttext-span">{sanitizeValue(data?.lote?.patrimonio)}</p>
                      <p className="ttext-span">{sanitizeValue(data?.lote?.situacaoLote)}</p>
                      <p className="ttext-span">{sanitizeValue(data?.lote?.pedologia)}</p>
                    </div>
                  </div>
                  <div className='tcontainer'>
                    <div className='tcontainer-p'>
                      <p className="ttext-table">MURO:</p>
                      <p className="ttext-table">PASSEIO:</p>
                      <p className="ttext-table">TOPOGRAFIA:</p>
                      <p className="ttext-table">NÍVEL:</p>
                    </div>
                    <div className='tcontainer-span-table'>
                      <p className="ttext-span">{sanitizeValue(data?.lote?.muro)}</p>
                      <p className="ttext-span">{sanitizeValue(data?.lote?.passeio)}</p>
                      <p className="ttext-span">{sanitizeValue(data?.lote?.topografia)}</p>
                      <p className="ttext-span">{sanitizeValue(data?.lote?.nivel)}</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
          {data?.unidade.length !== 0 && (
            <div>

              <section>
                <div className='tsection-header'>
                  <span>Informaçes da edificação</span>
                </div>

                <div className='tcontainer'>
                  <div className='tcontainer-p'>
                    <p className="ttext">ÁREA PRINCIPAL:</p>
                    <p className="ttext">ÁREA DEPENDENTES: </p>
                    <p className="ttext">ÁREA TOTAL: </p>
                  </div>
                  <div className='tcontainer-span-table'>
                    <p className="ttext-span">{sanitizeValue(data?.unidade?.areaConstrucao)} m²</p>
                    <p className="ttext-span">{sanitizeValue(data?.unidade?.areaEdicula)} m²</p>
                    <p className="ttext-span">{sanitizeValue(data?.unidade?.areaConstrucao + data?.unidade?.areaEdicula)} m²</p>
                  </div>

                  <div className='tcontainer-table'>
                    <header className='tcontainer-table-header'><p className='tcontainer-p-table'>CARACTERÍSTICAS DA EDIFICAÇÃO</p></header>
                    <div className='tcontainer'>
                      <div className='tcontainer'>
                        <div className='tcontainer-p'>
                          <p className="ttext-table">UTILIZAÇÃO:</p>
                          <p className="ttext-table">TIPO:</p>
                          <p className="ttext-table">ALINHAMENTO:</p>
                          <p className="ttext-table">POSIÇÃO:</p>
                          <p className="ttext-table">CLASSIFICAÇÃO:</p>
                          <p className="ttext-table">ESTRUTURA:</p>
                          <p className="ttext-table">COBERTURA:</p>
                        </div>
                        <div className='tcontainer-span-table'>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.utilizacao)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.tipo)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.alinhamento)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.posicao)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.classificacao)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.estrutura)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.cobertura)}</p>
                        </div>
                      </div>

                      <div className='tcontainer'>
                        <div className='tcontainer-p'>
                          <p className="ttext-table">INST. ELÉTRICA:</p>
                          <p className="ttext-table">CONSERVAÇÃO:</p>
                          <p className="ttext-table">ACESSO:</p>
                          <p className="ttext-table">ESQUADRIAS:</p>
                          <p className="ttext-table">PISO EXTERNO:</p>
                          <p className="ttext-table">REVEST. EXTERNO:</p>
                          <p className="ttext-table">INST. SANITÁRIA:</p>
                        </div>
                        <div className='tcontainer-span-table'>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.instEletrica)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.conservacao)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.acesso)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.esquadrias)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.piso)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.revestimento)}</p>
                          <p className="ttext-span">{sanitizeValue(data?.unidade?.instSanitaria)}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </section>
            </div>
          )}
        </div>

        <footer className='tfooter-content'>
          <div>
            <p className="ttext-footer">{sanitizeValue(data?.logradouro?.nome)}, {sanitizeValue(data?.endereco?.numero)}</p>
            <p className="ttext-footer">Data de emissão: {today}</p>
          </div>
        </footer>
      </div>

      {planta && <div className='tpage'>
        <div className="tpage-header">
          <div className="tdescription">
            <p className="ttext-header">PREFEITURA MUNICIPAL DE ITAJUBÁ</p>
            <p className="ttext-header">Emissão: {today}</p>
          </div>

          <div className="tcontent">
            <img src="./prefecture-icons/icon_itajuba.png" alt="logo" width="62" height="62" />
            <div className="ttitle">
              <h1 className="ttext-header-bold">PREFEITURA MUNICIPAL DE ITAJUBÁ</h1>
              <h2 className="ttext-header">ESTADO DE MINAS GERAIS</h2>
            </div>
          </div>
        </div>


        <div className='tcontent-planta' >
          <div>
            <h3>Planta</h3>
          </div>
          <div className='tcontent-map'>
            <section style={{ height: "100%" }}>
              <div className='tcontent-map' style={{ height: "100%", flexDirection: 'column' }}>
                <h5>Planta de situação</h5>
                <div className='tcontent-subtitle'>
                  <FirstMap ref={map1} result={data} handleCalculateExtent={handleCalculateExtent} />
                  <div className="tvalues-lat">
                    <p className="tsubtitle">{valuesLat[0]}</p>
                    <p className="tsubtitle">{valuesLat[1]}</p>
                    <p className="tsubtitle">{valuesLat[2]}</p>
                    <p className="tsubtitle">{valuesLat[3]}</p>
                  </div>
                  <div className="tvalues-lon">
                    <p className="tsubtitle">{valuesLon[0]}</p>
                    <p className="tsubtitle">{valuesLon[1]}</p>
                    <p className="tsubtitle">{valuesLon[2]}</p>
                    <p className="tsubtitle">{valuesLon[3]}</p>
                    <p className="tsubtitle">{valuesLon[4]}</p>
                    <p className="tsubtitle">{valuesLon[5]}</p>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div className='tcontent-map' style={{ height: "100%", flexDirection: 'column' }}>
                <h5>Planta de localização</h5>
                <SecondMap ref={map2} result={data} />
              </div>
              <div className='tcontent-map' style={{ height: "100%", flexDirection: 'column' }}>
                <h5>Ortofoto do lote</h5>
                <ThirdMap ref={map3} result={data} />
              </div>
            </section>
          </div>
          <div className="tinformation">
            {/* <img src={image} alt="image" width="150" /> */}
            <div>
              <h3 className="tlegend">Legenda</h3>
              <ul>
                <li>
                  <span
                    style={{
                      background: "rgba(240,59,32, 0.15)",
                      display: "inline-block",
                      width: "10px",
                      height: "10px",
                      marginRight: "5px",
                    }}
                  ></span>
                  Quadra
                </li>
                <li>
                  <span
                    style={{
                      background: "rgba(254,234,55,0.5)",
                      display: "inline-block",
                      width: "10px",
                      height: "10px",
                      marginRight: "5px",
                    }}
                  ></span>
                  Lote
                </li>
                <li>
                  <span
                    style={{
                      background: "rgba(128,128,128,0.3)",
                      display: "inline-block",
                      width: "10px",
                      height: "10px",
                      marginRight: "5px",
                    }}
                  ></span>
                  Cobertura
                </li>
                <li>
                  <span
                    style={{
                      background: "rgba(240,59,32)",
                      display: "inline-block",
                      width: "2px",
                      height: "10px",
                      marginRight: "5px",
                    }}
                  ></span>
                  Testada
                </li>
              </ul>
            </div>
            <div className="tdatum-sirgas">
              <p>Datum SIRGAS 2000</p>
              <p>Projeção Cartográfica UTM 23S</p>
              <p>Base cartográfica de Abril de 2023</p>
            </div>
          </div>


        </div>

        <footer className='tfooter-content'>
          <div>
            <p className="ttext-footer">{sanitizeValue(data?.logradouro?.nome)}, {sanitizeValue(data?.endereco?.numero)}</p>
            <p className="ttext-footer">Data de emissão: {today}</p>
          </div>
        </footer>
      </div>}

      {data?.arquivos
        .reduce((acc, _, index) => {
          if (index % 4 === 0) acc.push(data?.arquivos.slice(index, index + 4));
          return acc;
        }, [])
        .map((group, pageIndex) => (
          <div className='tpage' key={`page-${pageIndex}`} style={{ display: fotografia ? "block" : "none" }}>
            <div className="tpage-header">
              <div className="tdescription">
                <p className="ttext-header">PREFEITURA MUNICIPAL DE ITAJUBÁ</p>
                <p className="ttext-header">Emissão: {today}</p>
              </div>

              <div className="tcontent">
                <img src="./prefecture-icons/icon_itajuba.png" alt="logo" width="62" height="62" />
                <div className="ttitle">
                  <h1 className="ttext-header-bold">PREFEITURA MUNICIPAL DE ITAJUBÁ</h1>
                  <h2 className="ttext-header">ESTADO DE MINAS GERAIS</h2>
                </div>
              </div>
            </div>

            <div className='tcontent-fotografia'>
              <div>
                <h3>Fotografias</h3>
              </div>

              <div className='tcontente-img-fotografia'>
                {group.map(item => (
                  <div key={item.id}>
                    <img src={item.url} alt={`Imagem ${item.id}`} />
                  </div>
                ))}
              </div>
            </div>

            <footer className='tfooter-content'>
              <div>
                <p className="ttext-footer">{sanitizeValue(data?.logradouro?.nome)}, {sanitizeValue(data?.endereco?.numero)}</p>
                <p className="ttext-footer">Data de emissão: {today}</p>
              </div>
            </footer>
          </div>
        ))}
    </div>
  );
};

export default GeneratePdfComponent; 