import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { Button, Grid } from "@mui/material";
import { RecipeReviewCard } from "./componentsImage/RecipeReviewCard";
import UploadComponent from "./componentsImage/uploadComponent";
import { Api } from "../../../../../services/Api";

export const TabImagens = forwardRef(
  ({ id, dados, onUpdate, onClickImage }, ref) => {
    const [imagemTemporaria, setImagemTemporaria] = useState([]);
    const [uploads, setUploads] = useState([]);
    const [initImagens, setInitImagens] = useState([]);
    const imgRemovida = useRef([]); // Referência para imagens removidas
    const imgAdicionada = useRef([]); // Referência para imagens adicionadas

    // Fetch imagens AWS
    useEffect(() => {
      const fetchImagensAWS = async () => {
        try {
          const imagemAWS = await Api.getImagensStreetView(id);
          const imagens = imagemAWS.map((img) => img.images);
          const flatImg = imagens.flatMap((img) => img);

          setImagemTemporaria(flatImg);
        } catch (error) {
          console.error("Erro ao buscar a imagem do AWS:", error);
        }
      };

      fetchImagensAWS();
    }, [id]);

    // Initialize imagens from dados
    useEffect(() => {
      if (dados) {
        setInitImagens(dados || []);
      }
    }, [dados]);

    // Atualizar imgAdicionada quando uploads mudar
    useEffect(() => {
      imgAdicionada.current = [...imgAdicionada.current, ...uploads];
    }, [uploads]);

    useImperativeHandle(ref, () => ({
      getRefRemovida: () => imgRemovida.current,
      getRefAdicionada: () => imgAdicionada.current,
    }));

    const handleDelete = (imagemId) => {
      const imagemRemovida = initImagens.find((img) => img.id === imagemId);
      if (imagemRemovida) {
        imgRemovida.current = [...imgRemovida.current, imagemRemovida];
        setInitImagens((prev) => prev.filter((img) => img.id !== imagemId));
      }
    };

    // const atualizarDadosImagens = () => {
    //   const imagensSelecionadas = imagemTemporaria.filter(
    //     (imagem) => imagem.selected === true
    //   );
    //   const imagensInfo = imagensSelecionadas.map((imagem) => ({
    //     imagemId: imagem.imagemId,
    //     nome: imagem.nome_imagem,
    //     angulo: imagem.angle,
    //     path: imagem.path,
    //   }));

    //   // onUpdate(imagensInfo);
    // };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          height: "75vh",
          // maxHeight: "75vh", // Define a altura máxima
          overflowY: "auto", // Permite a rolagem vertical
          width: "100%", // Define a largura total (opcional, ajuste conforme necessário)
        }}
      >
        {/* <Grid container spacing={2} sx={{ paddingLeft: 0 }}> */}
        {/* Outros componentes */}
        {/* </Grid> */}
        {/* <Button onClick={atualizarDadosImagens}>Atualizar Dados</Button> */}

        <h5 style={{ paddingTop: "1rem", paddingBottom: "5px" }}>Uploads</h5>
        <Grid container spacing={2} sx={{ paddingLeft: 0 }}>
          <UploadComponent sendUploads={setUploads} />
          {initImagens.map((imagem) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={`${imagem.url}_${imagem.id}`}
              sx={{ height: 150 }}
            >
              <RecipeReviewCard
                inviteStreetView={() => "teste"}
                imagem={imagem}
                onDelete={() => handleDelete(imagem.id)}
                onAdd={() => console.log("Adicionar imagem")}
              />
            </Grid>
          ))}
        </Grid>
        <h5 style={{ paddingTop: "1rem", paddingBottom: "5px" }}>
          Imagens 360
        </h5>
        <Grid container spacing={2} sx={{ paddingLeft: 0 }}>
          {imagemTemporaria.map((imagem) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={`${imagem.url}_${imagem.id}`}
              sx={{ height: 150 }}
            >
              <RecipeReviewCard
                inviteStreetView={onClickImage}
                imagem={imagem}
                onDelete={() => handleDelete(imagem.id)}
                onAdd={() => console.log("Adicionar imagem")}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }
);
