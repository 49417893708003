import { Feature } from "ol";
import { LineString, Point } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import { getLength as getLineLength } from "ol/sphere";

export function fnAdicionarLabels(features, minDistance = 0.5) {
  const layers = [];

  features.forEach((feature) => {
    const geometry = feature.getGeometry();

    if (geometry.getType() === "MultiPolygon") {
      const coordinates = geometry.getCoordinates()[0][0];

      for (let i = 0; i < coordinates.length - 1; i++) {
        const start = coordinates[i];
        const end = coordinates[i + 1];

        // Calcula a distância entre os dois pontos
        const distance = getLineLength(new LineString([start, end]), {
          projection: "EPSG:31983",
        });

        // Ignora segmentos com distância menor que o valor mínimo
        if (distance < minDistance) {
          continue;
        }

        // Calcula o ponto médio do segmento
        const midPoint = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];

        // Ajuste opcional para evitar sobreposição (desloca o ponto ligeiramente)
        midPoint[0] -= 0.1;
        midPoint[1] -= 0.1;

        // Cria uma camada com o label
        const labelLayer = new VectorLayer({
          source: new VectorSource({
            features: [
              new Feature({
                geometry: new Point(midPoint),
                name: `${distance.toFixed(2)} m`,
              }),
            ],
          }),
          zIndex: 4,
          style: new Style({
            text: new Text({
              text: `${distance.toFixed(2)} m`,
              font: "10px Calibri,sans-serif",
              fill: new Fill({
                color: "#FFFFFF",
              }),
              stroke: new Stroke({
                color: "#000",
                width: 3,
              }),
            }),
            zIndex: 11,
          }),
        });

        layers.push(labelLayer);
      }
    }
  });

  return layers;
}
