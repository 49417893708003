import React, { forwardRef, useEffect, useRef } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { GeoJSON } from 'ol/format';
import { Fill, Stroke, Style, Text } from 'ol/style';
import { fnAddLabel } from '../utilsMap/fnAddLabel';

const SecondMap = forwardRef((props, ref) => {
  const { result } = props
  const mapRef = useRef(null);


  const handleSecondMap = (map) => {
    if (result && result.loteVizinhos) {
      result.loteVizinhos.map((loteVizinho) => {
        const newSource = new VectorSource({
          features: new GeoJSON().readFeatures(loteVizinho.geom, {
            dataProjection: "EPSG:31983",
            featureProjection: "EPSG:31983",
          }),
        });

        const vectorLayer = new VectorLayer({
          source: newSource,
          style: new Style({
            fill: new Fill({
              color: "rgba(217,217,217)",
            }),
            stroke: new Stroke({
              color: "rgba(150,150,150)",
              width: 2,
            }),
            zIndex: 100,
          }),
        });

        map.addLayer(vectorLayer);
        vectorLayer.setZIndex(2);

        const label = fnAddLabel(newSource, loteVizinho.id)
        map.addLayer(label);
        label.setZIndex(4);
      })

    }



    let newSource
    let zoom = 8
    if (result && result.lote) {
      newSource = new VectorSource({
        features: new GeoJSON().readFeatures(result.lote.geom, {
          dataProjection: "EPSG:31983",
          featureProjection: "EPSG:31983",
        }),
      });

      const vectorLayer = new VectorLayer({
        source: newSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(255,237,160)",
          }),
          stroke: new Stroke({
            color: "gray",
            width: 1,
          }),
          zIndex: 100,
        }),
      });
      map.addLayer(vectorLayer);
      vectorLayer.setZIndex(2);

      // Ajusta o zoom baseado no tamanho
      // Centraliza e aplica o zoom
      const label = fnAddLabel(newSource, result.lote.id, "rgba(255,237,160)", "gray")
      map.addLayer(label);
      label.setZIndex(4);
    }




    // Função para ajustar o zoom com base no bounding box
    const fitBoundingBox = (map, layer, padding = [2, 2, 2, 2], maxZoom = 18) => {
      const source = layer.getSource();
      const extent = source.getExtent();

      // Verificar se a extensão é válida
      if (!extent.every(Number.isFinite)) {
        console.warn("Nenhuma feição encontrada na camada.");
        return;
      }

      // Ajustar o mapa para enquadrar a extensão
      map.getView().fit(extent, {
        size: map.getSize(),
        padding, // Margem em pixels ao redor da geometria
        maxZoom, // Limite máximo de zoom
      });
    };

    if (result && result.geomQuadra.length > 0) {
      // Criar nova fonte de vetor
      const newSource = new VectorSource({
        features: new GeoJSON().readFeatures(result.geomQuadra[0].geom, {
          dataProjection: "EPSG:31983",
          featureProjection: "EPSG:31983",
        }),
      });

      // Criar a camada de vetor
      const vectorLayer = new VectorLayer({
        source: newSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(240,59,32, 0.15)",
          }),
          stroke: new Stroke({
            color: "rgba(240,59,32, 0.4)",
            width: 1,
          }),
          zIndex: 1,
        }),
      });

      // Adicionar a camada ao mapa
      map.addLayer(vectorLayer);
      vectorLayer.setZIndex(1);

      // Ajustar o zoom e centralizar usando o bounding box
      fitBoundingBox(map, vectorLayer);

      // Obter o zoom atual para passar ao rótulo
      zoom = map.getView().getZoom();

      // Adicionar o rótulo
      const label = fnAddLabel(newSource, result.geomQuadra[0].cod, "rgba(240,59,32, 0.6)", "#fff", zoom);
      map.addLayer(label);
      label.setZIndex(4);
    }


    map.getView().setZoom(zoom); // Define o nível de zoom para 8
  };

  useEffect(() => {
    if (ref.current) return
    let center = [452673.985418907, 7520803.474062003];
    let rotation = 0;

    if (result.lote) {
      const newSource = new VectorSource({
        features: new GeoJSON().readFeatures(result.lote.geom, {
          dataProjection: "EPSG:31983",
          featureProjection: "EPSG:31983",
        }),
      });

      const extent = newSource.getExtent();

      const dx = extent[2] - extent[0];
      const dy = extent[3] - extent[1];

      rotation = Math.atan2(dy, dx);
      center = [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];
    }
    const newMap = new Map({
      target: mapRef.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: center,
        maxZoom: 20,
        minZoom: 0,
        resolution: resolutions[5], // Escolha uma resolução mais baixa (mais distante)
        projection: "EPSG:31983",
        resolutions: resolutions,
      }),
      controls: [], // Sem controles
    });
    ref.current = newMap
    handleSecondMap(newMap)
  }, []);

  return (
    <div ref={mapRef} style={{ width: '100%', height: '100%', border: '2px solid black' }}></div>
  );
})

const resolutions = [
  2785.522278112486, 1392.761139056243, 696.3805695281216, 348.1902847640608,
  174.0951423820304, 87.0475711910152, 43.5237855955076, 21.7618927977538,
  10.8809463988769, 5.44047319943845, 2.720236599719225, 1.3601182998596124,
  0.6800591499298062, 0.3400295749649031, 0.1700147874824516,
  0.0850073937412258, 0.0425036968706129, 0.0212518484353064,
  0.0106259242176532, 0.0053129621088266, 0.0026564810544133,
];

SecondMap.displayName = "SecondMap";  // Define a display name explicitamente.

export { SecondMap };
