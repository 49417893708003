import { Feature, View } from "ol";
import { Polygon } from "ol/geom";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { Projection } from "ol/proj";
import { OSM, TileWMS } from "ol/source";
import VectorSource from "ol/source/Vector";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import proj4 from "proj4";
import { useEffect, useRef, useState, useCallback, forwardRef } from "react";
import { register } from "ol/proj/proj4.js";
import { Fill, Stroke, Style } from "ol/style";
import Map from "ol/Map";
import { GeoJSON } from "ol/format";
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { Control } from "ol/control";

const ThirdMap = forwardRef((props, ref) => {
    const { result } = props
    const mapRef = useRef();

    proj4.defs(
        "EPSG:31983",
        "+proj=utm +zone=23 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
    );
    register(proj4);

    const caxambuMapInfos = {
        projectionName: "EPSG:31983",
        projetionUnits: "m",
        projetionAxisOrientation: "neu",
        tileURL: `${process.env.REACT_APP_API_GEOSERVER_ROUTER}${process.env.REACT_APP_API_GEOSERVER_WMTS_PATH}`,
        tileLayer: "ITAJUBA:ITA_ORTO_5CM_GEOTIFF-WEB",
        tileGridSize: [100, 100],
        tileGridExtent: [
            446826.84914465, 7515831.60890135, 457626.84914465, 7524081.60890135,
        ],
        tileGridOrigin: [446826.84914465, 7524081.60890135],
        tileResolutions: [
            13.999999999999998, 6.999999999999999, 3.4999999999999996,
            1.7499999999999998, 0.8749999999999999, 0.4374999999999999, 0.21875,
            0.109375, 0.0546875,
        ],
        tileMatrixIds: [
            "EPSG:31983:0",
            "EPSG:31983:1",
            "EPSG:31983:2",
            "EPSG:31983:3",
            "EPSG:31983:4",
            "EPSG:31983:5",
            "EPSG:31983:6",
            "EPSG:31983:7",
            "EPSG:31983:8",
        ],
        tilesWMS: {
            url: `${process.env.REACT_APP_API_GEOSERVER_ROUTER}${process.env.REACT_APP_API_GEOSERVER_WORKSPACE_PATH}`,
            params: {
                LAYERS: `${process.env.REACT_APP_GEOSERVER_WORKSPACE}:`,
                tilesOrigin: {
                    first: 446826.84914465,
                    second: 7515831.60890135,
                    final: "446826.84914465,7515831.60890135",
                },
                VERSION: "1.1.1",
                format: "image/png",
                tiled: true,
            },
        },
    };

    const handleThirdMap = (ref) => {
        if (result && result.lote && result.lote.geom) {
            const coordinates = result.lote.geom.coordinates[0];
            const polygon = new Polygon(coordinates);
            const boundingBox = polygon.getExtent();
            // const polygon = new Polygon(boundingBox);

            const projectionCaxambuByGeoserver = new Projection({
                code: caxambuMapInfos.projectionName,
                units: caxambuMapInfos.projetionUnits,
                axisOrientation: caxambuMapInfos.projetionAxisOrientation,
            });

            const sourceCaxambuByGeoserver = new TileWMS({
                url: caxambuMapInfos.tilesWMS.url,
                params: {
                    LAYERS: caxambuMapInfos.tileLayer,
                    FORMAT: caxambuMapInfos.tilesWMS.params.format,
                    TILED: caxambuMapInfos.tilesWMS.params.tiled,
                    VERSION: caxambuMapInfos.tilesWMS.params.VERSION,
                    BBOX: boundingBox.join(','), // Adicionando BBOX
                },
                serverType: "geoserver",
                cacheSize: 4096,
                tileGrid: new WMTSTileGrid({
                    tileSize: caxambuMapInfos.tileGridSize,
                    extent: boundingBox, // Ajustando a grade para a extensão
                    origin: caxambuMapInfos.tileGridOrigin,
                    resolutions: caxambuMapInfos.tileResolutions,
                    matrixIds: caxambuMapInfos.tileMatrixIds,
                }),
                projection: projectionCaxambuByGeoserver,
                wrapX: true,
            });

            const layerCaxambuByGeoserver = new TileLayer({
                source: sourceCaxambuByGeoserver,
            });

            const newSource = new VectorSource({
                features: [new Feature(polygon)],
            });
            const vectorLayer = new VectorLayer({
                source: newSource,
                style: new Style({
                    stroke: new Stroke({
                        color: "red",
                        width: 2,
                    }),
                    fill: new Fill({
                        color: "rgba(255, 0, 0, 0.2)",
                    }),
                }),
            });

            const coordinatesFilter = result.lote.geom.coordinates[0][0];
            const extent = ref.getView().calculateExtent();
            const coordinatesExtent = [
                [extent[0], extent[1]],
                [extent[2], extent[1]],
                [extent[2], extent[3]],
                [extent[0], extent[3]],
                [extent[0], extent[1]],
            ];

            const temp = [
                coordinatesExtent[0],
                coordinatesExtent[1],
                coordinatesExtent[2],
                coordinatesExtent[3],
                coordinatesExtent[4],
                ...coordinatesFilter,
                coordinatesExtent[0],
            ];

            const polygonFilter = new Polygon([temp]);

            const newSourceFilter = new VectorSource({
                features: [new Feature(polygonFilter)],
            });

            const vectorLayerFilter = new VectorLayer({
                properties: {
                    name: "lote",
                },
                source: newSourceFilter,
                style: new Style({
                    fill: new Fill({
                        color: "rgba(255, 255, 255, 1)",
                    }),
                }),
            });
            ref.addLayer(layerCaxambuByGeoserver);
            ref.getView().setProperties("t");
            ref.addLayer(vectorLayerFilter);
            ref.getView().setMinZoom(13);
            ref.getView().setZoom(14);

            // Ajusta o mapa à extensão do polígono
            ref.getView().fit(boundingBox, { size: ref.getSize() });
        }
    };

    useEffect(() => {
        if (ref.current) return

        let center = [452673.985418907, 7520803.474062003];
        let rotation = 0;

        if (result.lote) {
            const newSource = new VectorSource({
                features: new GeoJSON().readFeatures(result.lote.geom, {
                    dataProjection: "EPSG:31983",
                    featureProjection: "EPSG:31983",
                }),
            });

            const extent = newSource.getExtent();

            const dx = extent[2] - extent[0];
            const dy = extent[3] - extent[1];

            rotation = Math.atan2(dy, dx);
            center = [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];
        }
        const newMap = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: null,
                }),
            ],
            view: new View({
                center: center,
                zoom: 12,
                projection: "EPSG:31983",
                resolutions: resolutions,
                maxZoom: 20,
                minZoom: 0,
            }),
            controls: [], // Sem controles
            interactions: [], // Sem interações
        });
        handleThirdMap(newMap);
        ref.current = newMap
    }, []);

    return (
        <div ref={mapRef} style={{ height: '100%', width: '100%', border: '2px solid black' }} />
    );
})

const resolutions = [
    2785.522278112486, 1392.761139056243, 696.3805695281216, 348.1902847640608,
    174.0951423820304, 87.0475711910152, 43.5237855955076, 21.7618927977538,
    10.8809463988769, 5.44047319943845, 2.720236599719225, 1.3601182998596124,
    0.6800591499298062, 0.3400295749649031, 0.1700147874824516,
    0.0850073937412258, 0.0425036968706129, 0.0212518484353064,
    0.0106259242176532, 0.0053129621088266, 0.0026564810544133,
];

ThirdMap.displayName = "ThirdMap";  // Define a display name explicitamente.

export { ThirdMap };

