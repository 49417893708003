import { GeoJSON } from "ol/format";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import { fnAdicionarLabels } from "../utilsMap/fnAdicionarLabels";
import { fnAddAreaLabel } from "../utilsMap/fnAddAreaLabel";
import { LineString, Point } from "ol/geom";
import { Feature, Map, View } from "ol";
import { getLength as getLineLength } from "ol/sphere";
import { forwardRef, useEffect, useRef } from "react";
import TileLayer from "ol/layer/Tile";
import { OSM } from "ol/source";
import { ScaleLine } from "ol/control";
import 'ol/ol.css';

const FirstMap = forwardRef((props, ref) => {
    const { result, handleCalculateExtent } = props
    const mapRef = useRef();

    const functionMap = (newMap) => {
        let newSource
        let zoom
        if (result && result.lote) {
            newSource = new VectorSource({
                features: new GeoJSON().readFeatures(result.lote.geom, {
                    dataProjection: "EPSG:31983",
                    featureProjection: "EPSG:31983",
                }),
            });

            const vectorLayer = new VectorLayer({
                source: newSource,
                style: new Style({
                    fill: new Fill({
                        color: "rgba(255,237,160)",
                    }),
                    stroke: new Stroke({
                        color: "#000",
                        width: 1,
                    }),
                    zIndex: 1,
                }),
            });
            newMap.addLayer(vectorLayer);

            const labels = fnAdicionarLabels(newSource.getFeatures());
            labels.forEach((labelLayer) => {
                newMap.addLayer(labelLayer);
            });

            const coordCenter = newSource.getExtent();
            const center = [
                (coordCenter[0] + coordCenter[2]) / 2,
                (coordCenter[1] + coordCenter[3]) / 2,
            ];

            // Calcula o tamanho do lote
            const extent = newSource.getExtent();
            const width = extent[2] - extent[0];
            const height = extent[3] - extent[1];
            const size = Math.max(width, height);

            // Ajusta o zoom baseado no tamanho
            //@ts-ignore
            const resolution = size / Math.min(newMap.getSize()[0], newMap.getSize()[1]);
            zoom = newMap.getView().getZoomForResolution(resolution);

            // Centraliza e aplica o zoom
            newMap.addLayer(fnAddAreaLabel(newSource));
        }

        if (result && result.areaCoberta) {
            result.areaCoberta.map((cobertura) => {
                const newSource = new VectorSource({
                    features: new GeoJSON().readFeatures(cobertura.geom, {
                        dataProjection: "EPSG:31983",
                        featureProjection: "EPSG:31983",
                    }),
                });

                const vectorLayer = new VectorLayer({
                    source: newSource,
                    style: new Style({
                        fill: new Fill({
                            color: "rgba(254,178,76, 0.6)",
                        }),
                        stroke: new Stroke({
                            color: "#757575",
                            width: 1,
                        }),
                        zIndex: 2,
                    }),
                });

                newMap.addLayer(vectorLayer);


                newSource.getFeatures().forEach((feature) => {
                    const geometry = feature.getGeometry();

                    if (geometry?.getType() === "Polygon") {
                        const coordinates = geometry.getCoordinates()[0]; // Pega as coordenadas do polígono

                        for (let i = 0; i < coordinates.length; i++) {
                            const start = coordinates[i];
                            const end = coordinates[(i + 1) % coordinates.length]; // Último segmento conecta ao primeiro

                            const distance = getLineLength(new LineString([start, end]), {
                                projection: "EPSG:31983",
                            });

                            // Aplica o filtro para excluir segmentos menores que 0.5m
                            if (distance > 0.5) {
                                // Calcula o ponto médio do segmento
                                const midPoint = [
                                    (start[0] + end[0]) / 2,
                                    (start[1] + end[1]) / 2,
                                ];

                                // Cria o rótulo para a distância
                                const label = new VectorLayer({
                                    source: new VectorSource({
                                        features: [
                                            new Feature({
                                                geometry: new Point(midPoint),
                                                name: `${distance.toFixed(2)} m`, // Nome da feature (opcional)
                                            }),
                                        ],
                                    }),
                                    style: new Style({
                                        text: new Text({
                                            text: `${distance.toFixed(2)} m`, // Texto do rótulo
                                            font: "10px Calibri,sans-serif", // Tamanho ajustado para legibilidade
                                            fill: new Fill({
                                                color: "#fff",
                                                width: 2
                                            }),
                                            stroke: new Stroke({
                                                color: "#7d7d7d",
                                                width: 3, // Cria um contorno para contraste
                                            }),
                                        }),
                                    }),
                                    zIndex: 10,
                                });

                                newMap.addLayer(label);
                            }
                        }
                    }
                });

            });
        }

        if (result && result.testadaPrincipal) {
            const testada = result.testadaPrincipal;
            const newSource = new VectorSource({
                features: new GeoJSON().readFeatures(testada.geom, {
                    dataProjection: "EPSG:31983",
                    featureProjection: "EPSG:31983",
                }),
            });

            const vectorLayer = new VectorLayer({
                source: newSource,
                style: new Style({
                    stroke: new Stroke({
                        color: "rgba(240,59,32)",
                        width: 4,
                    }),
                    zIndex: 3,
                }),
            });

            newMap.addLayer(vectorLayer);

            const extensao = testada.extensao || 0;

            const coordinates = testada.geom.coordinates[0];
            const start = coordinates[0];
            const end = coordinates[1];
            const midPoint = [(start[0] + end[0]) / 2, (start[1] + end[1]) / 2];

            const label = new VectorLayer({
                source: new VectorSource({
                    features: [
                        new Feature({
                            geometry: new Point(midPoint),
                            name: `${extensao.toFixed(2)} m`,
                        }),
                    ],
                }),
                style: new Style({
                    text: new Text({
                        text: `${extensao.toFixed(2)} m`,
                        font: "10px Calibri,sans-serif",
                        fill: new Fill({
                            color: "rgba(240,59,32)",
                        }),
                        stroke: new Stroke({
                            color: "#fff",
                            width: 2,
                        }),
                        offsetY: 20,
                    }),
                }),
            });
            // const boundingBox = newSource.getExtent();
            // newMap.getView().fit(boundingBox, { size: newMap.getSize() });

            newMap.addLayer(label);
        }
        newMap.getView().setZoom(zoom - 0.3); // Define o nível de zoom para 8
    }


    useEffect(() => {
        if (ref.current) return
        let center = [452673.985418907, 7520803.474062003];
        let rotation = 0;

        if (result.lote) {
            const newSource = new VectorSource({
                features: new GeoJSON().readFeatures(result.lote.geom, {
                    dataProjection: "EPSG:31983",
                    featureProjection: "EPSG:31983",
                }),
            });

            const extent = newSource.getExtent();

            const dx = extent[2] - extent[0];
            const dy = extent[3] - extent[1];

            rotation = Math.atan2(dy, dx);
            center = [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];
        }
        const newMap = new Map({
            target: mapRef.current,
            layers: [
                new TileLayer({
                    source: new OSM(),
                }),
            ],
            view: new View({
                center: center,
                maxZoom: 20,
                minZoom: 0,
                resolution: resolutions[5], // Escolha uma resolução mais baixa (mais distante)
                projection: "EPSG:31983",
                resolutions: resolutions,
            }),
            controls: [], // Sem controles
            interactions: [], // Sem interações
        });
        // Adicionando a escala métrica
        // Configuração da escala métrica
        function createScaleControl() {
            return new ScaleLine({
                units: 'metric',
                bar: true,
                steps: 6,
                text: true,
                minWidth: 140,
            });
        }

        newMap.addControl(createScaleControl());

        ref.current = newMap
        functionMap(newMap)
        handleCalculateExtent(newMap)
    }, []);


    return (
        <div ref={mapRef} style={{ height: '100%', width: '100%', border: '2px solid black' }} />
    );
})

FirstMap.displayName = "FirstMap";  // Define a display name explicitamente.

export { FirstMap };

const resolutions = [
    2785.522278112486, 1392.761139056243, 696.3805695281216, 348.1902847640608,
    174.0951423820304, 87.0475711910152, 43.5237855955076, 21.7618927977538,
    10.8809463988769, 5.44047319943845, 2.720236599719225, 1.3601182998596124,
    0.6800591499298062, 0.3400295749649031, 0.1700147874824516,
    0.0850073937412258, 0.0425036968706129, 0.0212518484353064,
    0.0106259242176532, 0.0053129621088266, 0.0026564810544133,
];