import { CardContent, Grid, Typography, CardMedia, Card, IconButton, CardHeader, Button } from "@mui/material";
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import { useRef, useState, useEffect } from "react";
import { Api } from "../../../../../../services/Api";
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';

const UploadComponent = ({ sendUploads }) => {
    const fileInputRef = useRef(null);
    const [images, setImages] = useState([]);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            addImage(URL.createObjectURL(file));
        }
    };

    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type.startsWith("image/")) {
                const file = items[i].getAsFile();
                addImage(URL.createObjectURL(file));
            }
        }
    };

    const addImage = (imageUrl) => {
        setImages((prevImages) => [...prevImages, imageUrl]);
    };

    const removeImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    };

    useEffect(() => {
        document.addEventListener("paste", handlePaste);
        return () => document.removeEventListener("paste", handlePaste);
    }, []);

    useEffect(() => {
        sendUploads(images)
    }, [images]);

    return (
        <>
            <Grid item xs={12} sm={6} md={4} >
                <CardContent onClick={handleClick} sx={{
                    height: 135,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: '2px dashed #ccc',
                    borderRadius: '4px',
                    boxSizing: 'border-box',
                    '&:last-child': { paddingBottom: '16px' },
                    '&:hover': {
                        cursor: 'pointer',
                        backgroundColor: '#f5f5f5',
                    },
                }}>
                    
                    <ImageSearchIcon sx={{ color: '#999', mb: 2 }} />
                    <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                        Adicione uma imagem!
                    </Typography>
                </CardContent>
                {/* Input de arquivo oculto */}
                <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </Grid>

            {images.map((imageUrl, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <CardContent sx={{
                        height: 135,
                        display: 'flex',
                        position: 'relative',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: '2px dashed #ccc',
                        borderRadius: '4px',
                        boxSizing: 'border-box',
                        '&:last-child': { paddingBottom: '16px' },
                        '&:hover': {
                            cursor: 'pointer',
                            backgroundColor: '#f5f5f5',
                        },
                    }}>
                        <IconButton aria-label="delete" size="small" style={{ position: 'absolute', top: 5, right: 5, padding: 0, minWidth: 0 }} onClick={() => removeImage(index)}>
                            <DoNotDisturbOnIcon fontSize="inherit" />
                        </IconButton>

                        <CardMedia
                            component="img"
                            height="95"
                            image={imageUrl}
                            alt={`Imagem ${index + 1}`}
                            sx={{
                                objectFit: 'contain',
                            }}
                        />
                    </CardContent>
                </Grid >
            ))}
        </>
    );
};

export default UploadComponent;
